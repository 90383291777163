/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useContext, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';
import CustomerSelectionDropdown from 'components/my-apps/CustomerSelectionDropdown';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false)

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu)
  };

  const setDropShadow = () => {
    const el = document.documentElement
    if (el.scrollTop > 0) {
      setShowDropShadow(true)
    } else {
      setShowDropShadow(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow)
    return () => window.removeEventListener('scroll', setDropShadow)
  }, []);

  return (
    <Navbar
      className={classNames('py-0 navbar-glass  fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as='div'
      >
        <button
          className='navbar-toggler-humburger-icon btn btn-link d-flex flex-center'
          onClick={handleBurgerMenu}
          id='burgerMenu'
        >
          <span className='navbar-toggle-icon'>
            <span className='toggle-line' />
          </span>
        </button>
      </Navbar.Toggle>

      <Navbar.Brand style={{ fontWeight: '600' }}>{process.env.REACT_APP_PRODUCT_NAME}</Navbar.Brand>

      <CustomerSelectionDropdown />
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
