/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import customerData from './customerData.json';

/**
 * This is the default customer data object, a template for what all customers have.  Each customer imported from the json file
 * will be merged with this object.
 *
 * New app configs can be added here as well.
 */
const defaultCustomerData = {
  id: -1,
  nickname: 'shawty',
  name: 'Default',
  key: 'DEFAULT',
  logo: 'https://www.roadys.com/wp-content/uploads/2018/07/roadys-logo.png',
  config: {
    apps: {
      driver_dashboard: {
        enabled: false
      },
      dfs: {
        enabled: false
      },
      rewards: {
        enabled: false
      }
    }
  }
};

/**
 * This function will merge two objects together, with the source object
 * taking precedence over the target object.
 * @param target
 * @param source
 * @returns {*}
 */
function deepMerge (target, source) {
  const result = { ...target };

  for (const key in source) {
    if (source[key] instanceof Object) {
      if (result[key] instanceof Object) {
        result[key] = deepMerge(result[key], source[key]);
      } else {
        result[key] = deepMerge({}, source[key]);
      }
    } else {
      result[key] = source[key];
    }
  }

  return result;
}

// Load an array of customer data from a json file located at the root directory.
const CustomerData = () => {
  customerData.forEach((customer, index) => {
    customerData[index] = deepMerge(deepMerge({}, defaultCustomerData), customer);
  });
  return JSON.stringify(customerData);
}

export default CustomerData;
