/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { navbarBreakPoint } from 'config';
import AppContext from 'context/Context';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import CustomerContext from 'context/CustomerContext';

const NavbarVertical = () => {
  const {
    config: {
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    }
  } = useContext(AppContext);

  const { customerHasApp } = useContext(CustomerContext);

  const [activeData, setActiveData] = useState(null);
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  useEffect(() => {
    setActiveData(routes);
  }, []);

  useEffect(() => {
    if (activeData !== null) {
      setFilteredRoutes(filterRoutes(routes));
    }
  }, [activeData]);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }

    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList])

  // Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as='li'>
      <Row className='mt-3 mb-2 navbar-vertical-label-wrapper'>
        <Col xs='auto' className='navbar-vertical-label navbar-vertical-label'>
          {label}
        </Col>
        <Col className='ps-0'>
          <hr className='mb-0 navbar-vertical-divider' />
        </Col>
      </Row>
    </Nav.Item>
  )

  /**
   * Takes an array and recursively removes whatever it's told to, by id.
   * @param list
   * @param id
   * @returns {*}
   */
  function recursiveRemove (list, id) {
    return list.map(item => { return { ...item } }).filter(item => {
      if ('children' in item) {
        item.children = recursiveRemove(item.children, id)
      }
      return item.id !== id
    })
  }

  /**
   * Filters the routes by iterating through them, and running them through
   * the routeFilters function.
   * @param routes
   * @returns {*}
   */
  function filterRoutes (routes) {
    return routes
  }

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant='light'
    >
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none',
          position: 'relative',
          marginTop: '69px'
        }}
      >
        <div className='navbar-vertical-content scrollbar'>
          <Nav className='flex-column' as='ul'>
            {filteredRoutes.map(route => {
              return (
                <Fragment key={route.label}>
                  {
                    route.label === 'Help'
                      ?
                      <hr/>
                      : ''
                  }
                  {!route.labelDisable && !route.hidden && customerHasApp(route.groups) && (<NavbarLabel label={capitalize(route.label)}/>)}
                    <NavbarVerticalMenu routes={route.children}/>
                </Fragment>
              )
            })}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}

NavbarVertical.propTypes = {
  label: PropTypes.string
}

export default NavbarVertical
