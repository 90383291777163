/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import gql from 'graphql-tag';

export const listUnreadUserNotifications = gql(`
  {
    listNotifications(filter: {read: {eq: false}}) {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
);

export const listAllUserNotifications = gql(`
  {
    listNotifications {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
);
