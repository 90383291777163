/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { postDataToAPI } from 'helpers/api';

const UserDFS = ({ driverId }) => {
  const [DFSData, setDFSData] = useState([]);
  const [DFSDataTable, setDFSDataTable] = useState(null);
  const [options, setOptions] = useState({
    driverId: driverId
  });

  /**
   * On initial page load.
   */
  useEffect(() => {
    getUserDFSData(options).then(data => {
      if (data) {
        setDFSData(data);
      }
    }).catch(e => {
      console.error('Error fetching user DFS data:', e);
    });
  }, [options]);

  /**
   * On DFS data change.
   */
  useEffect(() => {
    renderDFSData(DFSData);
  }, [DFSData]);

  /**
   * Get the user DFS data.
   * @param options
   * @returns {Promise<*>}
   */
  async function getUserDFSData (options) {
    const { driverId } = options;
    const sUrl = '/getUserDFSHistory';
    return await postDataToAPI(sUrl, {
      driverId: driverId,
      startdate: null,
      enddate: null
    });
  }

  /**
   * Renders the DFS data.
   * @param DFSData
   */
  function renderDFSData (DFSData) {
    let oDFSDataTable,
      oHistory;

    if (DFSData && Array.isArray(DFSData)) {
      oHistory = DFSData ?? [];
    } else {
      oHistory = [];
    }

    if (!oHistory || Object.keys(oHistory).length === 0) {
      oDFSDataTable = (<Row><Col><p>No DFS data found.</p></Col></Row>);
    } else {
      const aColumns = [
        {
          accessor: 'display_lid',
          Header: 'LID',
          HeaderProps: { className: 'text-nowrap' },
        },
        {
          accessor: 'location_name',
          Header: 'Location',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'fleet_co',
          Header: 'Fleet Company',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'code',
          Header: 'Internal Code',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'reserved_on',
          Header: 'Reserved On',
          HeaderProps: { className: 'text-nowrap' },
        },
        {
          accessor: 'redemption_started',
          Header: 'Redemption Started',
          HeaderProps: { className: 'text-nowrap' },
        },
        {
          accessor: 'completed_on',
          Header: 'Completed On',
          HeaderProps: { className: 'text-nowrap' },
        },
        {
          accessor: 'gallons',
          Header: 'Gallons',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'status',
          Header: 'Status',
          HeaderProps: { className: 'text-nowrap' }
        }

      ];

      oDFSDataTable = (
        <AdvanceTableWrapper
          columns={aColumns}
          data={oHistory}
          sortable
          pagination
          perPage={10}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={oHistory.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      );
    }
    setDFSDataTable(oDFSDataTable);
  }

  /**
   * Finally, return the component.
   */
  let oReturn;
  if (!DFSDataTable) {
    oReturn = (
      <div>
        <Skeleton count={5} />
      </div>
    );
  } else {
    oReturn = (
      <div>
        {DFSDataTable}
      </div>
    );
  }

  return (<>{oReturn}</>);
}

/**
 * Set up the prop stuff.
 * @type {{report: Validator<NonNullable<string>>, locations: Validator<NonNullable<any[]>>}}
 */
UserDFS.propTypes = {
  driverId: PropTypes.string
}

export default UserDFS;
