/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import AuthLayout from './layouts/AuthLayout';
import { AccountProvider, useAccountContext } from 'context/Account';
import { FeatureFlagsProvider } from 'context/FeatureFlags';

const App = () => {
  return (
    <AccountProvider>
      <FeatureFlagsProvider>
        <RouterWrapper />
      </FeatureFlagsProvider>
    </AccountProvider>
  )
}

export default App

const RouterWrapper = () => {
  const { appState } = useAccountContext()

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {
        appState.authenticated || process.env.REACT_APP_ENV === 'demo'
          ? <Layout />
          : <AuthLayout />
      }
    </Router>
  )
}
