/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import EditUser from 'modules/driver-dashboard/components/EditUser';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Divider from 'components/common/Divider';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UserRewards from 'modules/rewards/components/UserRewards';
import UserDFS from 'modules/dfs/components/UserDFS';
import DriverDashboardLogo from 'modules/driver-dashboard/assets/images/dd-icon.png';
import { postDataToAPI } from 'helpers/api';

const DriverDashboardUserDetails = (props) => {
  // Read the user id from the URL.
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [oPageHeader, setPageHeader] = useState(null);
  const [oUserTable, setUserTable] = useState(
    <Row>
      <Col>
        <p>User data is not yet available.</p>
      </Col>
    </Row>
  );
  const [oUserTransactionTable, setUserTransactionTable] = useState(
    <Row>
      <Col>
        <p>Transaction data is not yet available.</p>
      </Col>
    </Row>
  );

  /**
   * On load trigger, sets the page header.
   */
  useEffect(() => {
    setPageHeader(
      <PageHeader image={DriverDashboardLogo}
                  title={'Driver Dashboard User Details'}
                  description="Viewing details for user."
                  className='mb-3'/>
    );
  }, []);

  /**
   * On userId change, loads the user.
   */
  useEffect(() => {
    if (userId) {
      if (userId) {
        loadUser(userId).then(r => {
          // console.log('User:', r);
        });
      }
    }
  }, [userId]);

  /**
   * Whenever user changes, call render user.
   */
  useEffect(() => {
    if (user) {
      const sBackLink = '<a href="/modules/driver-dashboard/pages/user-manager">&larr; Back to User Manager</a>';
      setPageHeader(
        <PageHeader image={DriverDashboardLogo}
                    title={'Driver Dashboard User Details for: ' + user.name}
                    description={sBackLink}
                    className='mb-3'/>
      );
      renderUser(user);
    }
  }, [user]);

  const renderUser = (oUser) => {
    const oUserTable = (
      <div id={'user-table'}>

        <Card>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Container fluid={'true'}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Driver Dashboard Pool Enabled</Form.Label>
                          <Form.Control type="text" value={oUser.pool_user_status.enabled ? 'YES' : 'NO'} readOnly/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Driver Dashboard Pool Status</Form.Label>
                          <Form.Control type="text" value={oUser.pool_user_status.status} readOnly/>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <Divider/>

        <Card>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Container fluid={'true'}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>User ID</Form.Label>
                          <Form.Control type="text" value={oUser.id} readOnly/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Cognito User</Form.Label>
                          <Form.Control type="text" value={oUser.user} readOnly/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" value={oUser.name} readOnly/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" value={oUser.email} readOnly/>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Mobile Phone</Form.Label>
                          <Form.Control type="text" value={oUser.mobile_phone} readOnly/>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
    setUserTable(oUserTable);
    renderUserTabs(oUser);
  };

  /**
   * Renders the user transactions in a tabbed interface.  I think perhaps it would make snes to only
   * initially display the appropriate information per program for the users, For example for DFS we'll
   * show the information we get with the user pull, ie: first and last transaction dates.  For rewards,
   * we'll show the rewards card number, balance, showers, issuer, activation, last transaction, and status.
   * This way the top level details can be seen at a glance, and the user can click on the tab to see more
   * detailed information.
   *
   * Ideally also we can have a "Load DFS Transactions" and a "Load Rewards Transactions" button that will
   * pull and populate that data only when requested, for performance reasons.
   * @param oUser
   */
  const renderUserTabs = (oUser) => {
    const oDFSTab = (
      <Tab key='dfs-tab-key' eventKey='dfs-tab-key' title='DFS' className='border p-3'>
        <Container fluid={true}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>DFS First Transaction</Form.Label>
                <Form.Control type="text" value={oUser.dfs_first_trx} readOnly/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>DFS Last Transaction</Form.Label>
                <Form.Control type="text" value={oUser.dfs_last_trx} readOnly/>
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <Divider/>
        <UserDFS driverId={oUser.id} />
      </Tab>
    );

    let oRewardsTab;
    if (oUser.rewards_card && oUser.rewards_card_number) {
      oRewardsTab = (
        <Tab key='rewards-tab-key' id='rewards-tab-key' eventKey='rewards-tab-key' title='Rewards'
             className='border p-3'>
          <Container fluid={true}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Card Number</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.cardNumber} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Card Status</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.active ? 'active' : 'inactive'} readOnly/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Balance</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.balance.usd} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Showers</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.balance.shw} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Rewards Card Issuer</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.issuer} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Card Activation</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.activation} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Card First Trx</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.firstTransaction} readOnly/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Card Last Trx</Form.Label>
                  <Form.Control type="text" value={oUser.rewards_card.lastTransaction} readOnly/>
                </Form.Group>
              </Col>
            </Row>
          </Container>
          <Divider/>
          <UserRewards cardNumber={oUser.rewards_card_number}/>
        </Tab>
      );
    } else {
      oRewardsTab = (
        <Tab key='rewards-tab-key' id='rewards-tab-key' eventKey='rewards-tab-key' title='Rewards'
             className='border p-3'>
          <Container fluid={true}>
            <Row>
              <Col>
                <p>No rewards card found linked to this user.</p>
              </Col>
            </Row>
          </Container>
        </Tab>
      );
    }

    const aFinalTabs = [];
    aFinalTabs.push(oDFSTab);
    aFinalTabs.push(oRewardsTab);

    const oTransactionsTabs = (
      <Tabs defaultActiveKey="rewards-tab-key" id="uncontrolled-tab-example" className='mb-3'>
        {aFinalTabs}
      </Tabs>
    );

    setUserTransactionTable(oTransactionsTabs);
  }

  /**
   * Fetches a user from the driver dashboard user pool(s).
   * @returns {Promise<void>}
   */
  async function fetchDDUser (nUserId) {
    const sQuery = `
       query MyQuery {
          getUser(id: "` + nUserId + `") {
            id
            home_phone
            env
            email
            createdAt
            city
            zip
            user
            updatedAt
            stripe_id
            street
            state
            rewards_card_number
            name
            mobile_phone_opt_in
            mobile_phone
            rewards_card {
              activation
              active
              cardNumber
              expiration
              firstTransaction
              issuer
              lastTransaction
              balance {
                gal
                shw
                usd
              }
            }
            pool_user_status {
              enabled
              status
            }
          }
      }`;

    try {
      const sUrl = '/queryAppsync';
      const response = await postDataToAPI(sUrl, {
        roleArn: 'arn:aws:iam::337228034222:role/dashboard-data-interstate-appsync',
        query: sQuery
      });

      return await response.data; // Return the data
    } catch (error) {
      console.error('Error in fetchDDUsers:', error);
      throw error; // Re-throw the error to propagate it
    }
  }

  /**
   * Loads users from a user pool.
   */
  async function loadUser (nUserId) {
    await fetchDDUser(nUserId).then((response) => {
      const oData = JSON.parse(response.body);
      const oUser = oData.data.getUser;
      setUser(oUser);
    });
  }

  return (
    <>
      {oPageHeader}
      {oUserTable}
      <Divider/>
      {oUserTransactionTable}
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object
}

EditUser.defaultProps = {
  user: {}
}

export default DriverDashboardUserDetails
