/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import { Button, Card } from 'react-bootstrap';
import DFSLogo from 'modules/dfs/assets/images/dfs-icon.png';
import React from 'react';
import MyAppsCard from 'components/my-apps/MyAppsCard';

const DFSCard = () => {
  const aData = {
    label: 'DFS',
    description: 'Direct Fuel Sales',
    id: 'dfs',
    icon: 'truck',
    to: 'modules/dfs/pages/index',
    active: true,
    logo: DFSLogo,
  };

  return (
    <MyAppsCard id={aData.id}
      label={aData.label}
      description={aData.description}
      logo={aData.logo}
      to={aData.to}
    />
  );
};

DFSCard.getGroups = () => ['dfs'];

export default DFSCard;
