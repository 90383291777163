/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { createContext, useEffect, useState } from 'react';
import CustomerData from 'CustomerData'; // Assuming the path is correct
import PropTypes from 'prop-types'; // Import PropTypes

const CustomerContext = createContext(null);

export const CustomerProvider = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(() => {
    // Check if selectedCustomer exists in localStorage
    const storedCustomer = localStorage.getItem('selectedCustomer');
    return storedCustomer ? JSON.parse(storedCustomer) : null;
  });

  const [customers, setCustomers] = useState([]);

  CustomerProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  /**
   * When the component mounts, fetch customer data from the server
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CustomerData(); // Call the CustomerData component
        const parsedData = JSON.parse(response); // Parse the returned JSON string
        setCustomers(parsedData);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        // Handle errors appropriately
      }
    };

    fetchData();
  }, []);

  /**
   * Store selectedCustomer in localStorage
   */
  useEffect(() => {
    // Store selectedCustomer in localStorage
    localStorage.setItem('selectedCustomer', JSON.stringify(selectedCustomer));
  }, [selectedCustomer]);

  /**
   * Handle customer selection
   * @param customer
   */
  const handleCustomerSelection = (customer) => {
    console.log('Customer context switched to: ', customer);
    setSelectedCustomer(customer);
  };

  /**
   * Check if a customer has a specific app
   * @param app
   * @returns {boolean}
   */
  const customerHasApp = (app) => {
    let bReturn = false;
    // If app is null or undefined, return false.
    if (!app) {
      return false;
    }

    // If app is a string, make it an array.
    if (typeof app === 'string') {
      app = [app];
    }

    // if app contains 'all' or 'my-apps' or 'your-apps' return true
    if (app.includes('all') || app.includes('my-apps') || app.includes('your-apps')) {
      return true;
    }

    if (selectedCustomer) {
      // console.log('Checking customer: [', selectedCustomer.name, '] for apps: ', app);
      if (selectedCustomer.config) {
        if (selectedCustomer.config.apps) {
          if (Array.isArray(app)) {
            bReturn = app.every(a => {
              // console.log('Checking for app: ', a); // Log the current app
              const appExists = a in selectedCustomer.config.apps; // Check if the app exists
              let appEnabled = false;
              // console.log('App exists: ', appExists ? 'Yes' : 'No', ' in customer: ', selectedCustomer); // Log whether the app exists
              if (appExists) {
                // Now check if the app is enabled
                appEnabled = selectedCustomer.config.apps[a].enabled;
              }
              return appExists && appEnabled; // Return the result          } else {
            });
            // console.log('Customer has app: ', bReturn ? 'Yes' : 'No');
          }
        }
      }
    }
    return bReturn;
  };

  return (
    <CustomerContext.Provider value={{ selectedCustomer, setSelectedCustomer, handleCustomerSelection, customerHasApp, customers }}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
