/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import TagManager from 'react-gtm-module';
import { CustomerProvider } from 'context/CustomerContext';

const tagManagerArgs = {
  gtmId: 'G-TN711NPXMJ'
};

TagManager.initialize(tagManagerArgs);

// Render your app using ReactDOM.render
ReactDOM.render(
  <React.StrictMode>
    <CustomerProvider>
      <Main>
        <App />
      </Main>
    </CustomerProvider>
  </React.StrictMode>,
  document.getElementById('main')
);
