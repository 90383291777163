/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * MyAppsCard component
 * @param props
 * @returns {Element}
 * @constructor
 */
const MyAppsCard = (props) => {
  const id = props.id;
  const logo = props.logo;
  const label = props.label;
  const description = props.description;
  const to = props.to;
  const groups = props.groups;
  const buttonText = props.buttonText ?? 'Launch App';

  const oStyle = {
    minHeight: '22rem'
  };

  const oContentStyle = {
    flex: 1
  };

  const oButtonStyle = {
    alignSelf: 'flex-end',
    margin: '5px auto 0 auto'
  };

  return (
    <div className='col-lg-2 col-md-4 col-sm-6 mb-3'>
      <Card id={id} style={oStyle} data-groups={JSON.stringify(groups)}>
        <Card.Img style={{ width: '100%' }} src={logo} variant='top'/>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={oContentStyle}>
            <Card.Title as='h5'>{label}</Card.Title>
            <Card.Text>{description}</Card.Text>
          </div>
          <Link to={to} className="btn btn-primary" size="sm" style={oButtonStyle}>
            {buttonText}
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

MyAppsCard.propTypes = {
  id: PropTypes.string,
  logo: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
  buttonText: PropTypes.string,
  groups: PropTypes.array
};

export default MyAppsCard;
