/* jshint esversion: 11 */
/* jshint node: true */
'use strict';
import gql from 'graphql-tag';

export const updateNotificationToRead = gql(`
  mutation ($id: ID!) {
    updateNotification(input: {id: $id, read: true}) {
      read
      message
      id
      createdAt
      title
      type
      updatedAt
    }
  }`
);
