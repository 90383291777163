/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import { Button, Card } from 'react-bootstrap';
import DriverDashboardLogo from 'modules/driver-dashboard/assets/images/dd-icon.png';
import DriverDashboardRoutes from '../routes';
import MyAppsCard from 'components/my-apps/MyAppsCard';

const DriverDashboard = () => {
  // We want to look throgh the routes.js file located in the /modules/driver-dashboard folder, and create
  // a MyAppsCard for each route that is not the roo route, or not hidden.

  // Load the routes.js file.
  const routes = DriverDashboardRoutes.children;
  console.log('Driver Dashboard Routes:', routes);
  // loop through the routes and create a MyAppsCard for each route that is not the root route, or not hidden.
  const routeCards = [];
  routes.forEach((route) => {
    if (route.name !== 'Main' && !route.hidden) {
      routeCards.push(
        <MyAppsCard key={route.id} id={route.id} label={route.name} description={route.description} logo={route.logo} to={route.to} buttonText={'GO'}/>
      );
    }
  });

  return (
    <>
      <PageHeader
        image={DriverDashboardLogo}
        title='Driver Dashboard'
        description="Driver Dashboard Control Panel"
        className='mb-3'
      />
      <div className='row'>
        <div className='col-12'>
          {routeCards}
        </div>
      </div>
    </>
  )
}

export default DriverDashboard
