import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationDropdown from 'components/navbar/top/NotificationDropdown'
import ProfileDropdown from 'components/navbar/top/ProfileDropdown'
import React, { useEffect, useState } from 'react'
import {
  Nav,
  NavDropdown
} from 'react-bootstrap'
import { useFeatureFlagsContext } from 'context/FeatureFlags'
import { useNotificationContext } from 'context/Notification'

const TopNavRightSideNavItem = () => {
  const { featureFlagsState } = useFeatureFlagsContext()
  const { notificationState } = useNotificationContext()
  const [currentFeatureFlagsState, setCurrentFeatureFlagsState] = useState(null)

  const [activeData, setActiveData] = useState(null)

  const [currentName, setCurrentName] = useState(null)
  const [currentIcon, setCurrentIcon] = useState(null)
  const [appHasNotifications, setAppHasNotifications] = useState(true)
  const [notificationCount, setNotificationCount] = useState(0)

  useEffect(() => {
    setCurrentFeatureFlagsState(featureFlagsState)
  }, [featureFlagsState])

  useEffect(() => {
    if (notificationState.notifications !== null) {
      setNotificationCount(notificationState.notifications.length)
    }
  }, [notificationState.notifications])

  useEffect(() => {
    if (currentFeatureFlagsState !== null && Object.prototype.hasOwnProperty.call(currentFeatureFlagsState, 'notifications')) {
      setAppHasNotifications(currentFeatureFlagsState.notifications.enabled)
    }
  }, [currentFeatureFlagsState])

  useEffect(() => {
    if (activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setCurrentIcon('globe')
          setCurrentName(activeData.name)
          break
        case 'c':
          setCurrentName(activeData.name)
          setCurrentIcon('city')
          break
        case 'l':
          setCurrentName(activeData.name + ' (' + activeData.id + ')')
          setCurrentIcon('building')
          break
      }
    }
  }, [activeData])

  return (
    <Nav
      navbar
      className='navbar-nav-icons ms-auto flex-row align-items-center'
      as='ul'
    >
      {
        appHasNotifications
          ?
            <NotificationDropdown />
          : ''
      }
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem
