/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import { Button, Card } from 'react-bootstrap';
import DriverDashboardLogo from 'modules/driver-dashboard/assets/images/dd-icon.png';
import React from 'react';
import MyAppsCard from 'components/my-apps/MyAppsCard';

const DriverDashboardCard = () => {
  const aData = {
    label: 'Driver Dashboard',
    description: 'Driver Dashboard',
    id: 'driver-dashboard',
    icon: 'truck',
    to: 'modules/driver-dashboard/pages/index',
    active: true,
    logo: DriverDashboardLogo
  };

  return (
    <MyAppsCard id={aData.id}
      label={aData.label}
      description={aData.description}
      logo={aData.logo}
      to={aData.to}
    />
  );
};

DriverDashboardCard.getGroups = () => ['driver_dashboard'];

export default DriverDashboardCard;
