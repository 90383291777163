/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import CustomerContext from 'context/CustomerContext';

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();
  const { customerHasApp } = useContext(CustomerContext);

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  let oReturn;
  if (customerHasApp(route.groups) || route.groups.includes('my-apps')) {
    oReturn = (
      <Nav.Item as='li'>
        <Nav.Link
          onClick={() => {
            setOpen(!open)
          }}
          className={classNames('dropdown-indicator cursor-pointer', {
            'text-500': !route.active
          })}
          aria-expanded={open}
        >
          <NavbarVerticalMenuItem route={route}/>
        </Nav.Link>
        <Collapse in={open}>
          <Nav className='flex-column nav' as='ul'>
            <NavbarVerticalMenu routes={route.children}/>
          </Nav>
        </Collapse>
      </Nav.Item>
    );
  } else {
    oReturn = null;
  }
  return oReturn;
}

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
}

const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext)
  const { customerHasApp } = useContext(CustomerContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu)
    }
  }

  return routes.map(route => {
    if (!route.children) {
      let oNavItem = null;
      if ((!route.labelDisable && !route.hidden && customerHasApp(route.groups)) || route.groups.includes('my-apps')) {
        oNavItem = (
          <Nav.Item as='li' key={route.name} onClick={handleNavItemClick}>
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === '/authentication-modal' }}
              className={({ isActive }) =>
                isActive && route.to !== '#!' ? 'active nav-link' : 'nav-link'}
            >
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </Nav.Item>
        )
      }
      return oNavItem;
    }
    if (customerHasApp(route.groups) || route.groups.includes('my-apps')) {
      return <CollapseItems route={route} key={route.name} />
    }
    return null;
  });
}

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
}

export default NavbarVerticalMenu
