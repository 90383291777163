/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React from 'react';
import DFSMain from 'modules/dfs/pages/index';
import DFSReports from 'modules/report-tracker/pages/dfs-reports';

const DFSRoutes = {
  label: 'DFS',
  id: 'dfs',
  groups: ['dfs'],
  children: [
    {
      name: 'Main',
      id: 'dfs-index',
      groups: ['dfs'],
      icon: 'house',
      active: true,
      to: 'modules/dfs/pages/index',
      component: <DFSMain />
    },
    {
      name: 'Reports',
      id: 'dfs-reports',
      groups: ['dfs'],
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Redemptions',
          id: 'dfs-report-redemptions',
          groups: ['dfs'],
          active: true,
          to: 'modules/dfs/pages/reports/redemptions',
          component: 'modules/dfs/pages/reports/redemptions'
        },
        {
          name: 'Reservations',
          id: 'dfs-report-reservations',
          groups: ['dfs'],
          active: true,
          to: 'modules/dfs/pages/reports/reservations',
          component: 'modules/dfs/pages/reports/reservations'
        },
        {
          name: 'Sent Reports',
          id: 'dfs-sent-reports',
          groups: ['dfs'],
          active: true,
          to: 'modules/report-tracker/pages/dfs-reports',
          component: <DFSReports />
        }
      ]
    }
  ]
}

export default DFSRoutes
