import React, { useEffect, useState } from 'react'
import AppMenu from './AppMenu'

const Dashboard = () => {
  const [activeData, setActiveData] = useState(null)
  const [board, setBoard] = useState(<></>)

  useEffect(() => {
    setBoard(<AppMenu/>);
  }, [activeData])

  useEffect(() => {
    setBoard(<AppMenu />);
  }, [])

  return (
    board
  )
}

export default Dashboard
