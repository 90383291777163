/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { postDataToAPI } from 'helpers/api';

/**
 * Edit User component
 * @param props
 * @returns {Element}
 * @constructor
 */
const EditUser = (props) => {
  const oUser = props.user;
  const sPool = props.pool;
  const cancelCallback = props.cancel;
  const saveCallback = props.save;

  const defaultUser = {
    id: oUser.id || '',
    name: oUser.name || '',
    email: oUser.email || '',
    mobile_phone: oUser.mobile_phone || ''
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(defaultUser);

  /**
   * On load trigger
   */
  useEffect(() => {
    setFormData({
      ...formData,
      id: oUser.id,
      name: oUser.name,
      email: oUser.email,
      mobile_phone: oUser.mobile_phone
    });
  }, [oUser]);

  /**
   * Handles the field change
   */
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  /**
   * Handles the cancel button click
   */
  const handleCancel = () => {
    console.log('CANCELLING');
    cancelCallback();
  };

  /**
   * Edit user request
   * @param oPayload
   * @returns {Promise<any>}
   */
  const editUserRequest = async (oPayload) => {
    const sUrl = '/queryAppsync';
    const sQuery = `
      mutation {
        updateUser(input: {
          id: "` + oPayload.id.trim() + `", 
          name: "` + oPayload.name.trim() + `", 
          mobile_phone: "` + oPayload.mobile_phone.trim() + `", 
          email: "` + oPayload.email.trim() + `"
        }) {
          id
          name
          mobile_phone
          email
        }
      }`;

    console.log('editUserRequest was hit with query: ', sQuery);
    console.log('QUERYING URL: ', sUrl);

    try {
      const response = await postDataToAPI(sUrl, {
        roleArn: sPool,
        query: sQuery
      });

      return await response.data; // Return the data
    } catch (error) {
      console.error('Error in editUserRequest:', error);
      throw error; // Re-throw the error to propagate it
    }
  };

  /**
   * Handles the form submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const oPayload = {
        ...formData,
        id: oUser.id,
        name: formData.name,
        email: formData.email,
        mobile_phone: formData.mobile_phone
      };

      console.log('Submitting form data: ', oPayload, ' for user: ', oUser.id);
      const oResponse = await editUserRequest(oPayload);
      setIsProcessing(false);

      if (oResponse.statusCode !== 200) {
        toast.error('Error: ' + oResponse.status, {
          autoClose: false
        });
        return false;
      }

      if (!oResponse || !oResponse.body) {
        toast.error('Successful but unknown response received from server', {
          autoClose: false
        });
        return false;
      }

      if (oResponse.error) {
        toast.error(oResponse.error.message, {
          autoClose: false
        });
        return false;
      }

      toast.success('Edit user request successfully posted');
      saveCallback();
    } catch (error) {
      setIsProcessing(false);
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      );
    }
  };

  /**
   * Render the component
   */
  return (
    <>
      <Modal
        centered
        show={true}
        onHide={handleCancel}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} lg={12} controlId='editDevice'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      name='id'
                      type='text'
                      value={oUser.id}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name='name'
                      type='text'
                      value={formData.name}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name='email'
                      type='text'
                      value={formData.email}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      name='mobile_phone'
                      type='text'
                      value={formData.mobile_phone}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row className='mb-3 g-3'>
                <Col className='text-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg='2'>
                  <Button
                    variant='primary'
                    type='submit'
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object,
  pool: PropTypes.string,
  save: PropTypes.func,
  cancel: PropTypes.func,
}

EditUser.defaultProps = {
  user: {},
  pool: '',
  save: () => { return true; },
  cancel: () => { return true; }
}

export default EditUser
