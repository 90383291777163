import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validator from 'validator'
// eslint-disable-next-line
import { IMaskInput } from 'react-imask'
import parsePhoneNumber from 'libphonenumber-js'

import { useAccountContext } from 'context/Account'

const validatePhoneNumber = number => {
  return validator.isMobilePhone(number, ['en-US'])
}

const RegistrationForm = ({ hasLabel, success }) => {
  const { signup } = useAccountContext()
  // State
  const [isRegistering, setRegistering] = useState(false)
  const [isSubmittable, setIsSubmittable] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    phonenumber: '',
    firstname: '',
    lastname: '',
    company: ''
  })
  const mask = '(000) 000-0000'

  useEffect(() => {
    if (
      formData.email &&
      formData.password &&
      validatePhoneNumber(formData.phonenumber) &&
      formData.firstname &&
      formData.lastname
    ) {
      setIsSubmittable(true)
    } else {
      setIsSubmittable(false)
    }
  }, [formData])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setRegistering(true)
    const phoneNumber = parsePhoneNumber(formData.phonenumber, 'US')

    const attributes = {
      name: formData.firstname + ' ' + formData.lastname,
      phone_number: phoneNumber.number
    }

    try {
      const user = await signup(formData.email, formData.password, attributes)
      success(user.username)
    } catch (error) {
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email Address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email Address' : ''}
          name='email'
          onChange={handleFieldChange}
          type='text'
          value={formData.email}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name='password'
          onChange={handleFieldChange}
          type='password'
          value={formData.password}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>First Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'First Name' : ''}
          name='firstname'
          onChange={handleFieldChange}
          type='text'
          value={formData.firstname}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Last Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Last Name' : ''}
          name='lastname'
          onChange={handleFieldChange}
          type='text'
          value={formData.lastname}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Phone Number *</Form.Label>}
        {/* <Form.Control
          value={formData.phonenumber}
          placeholder={!hasLabel ? 'Phone Number' : ''}
          name="phonenumber"
          onChange={handleFieldChange}
          type="text"
        /> */}
        <IMaskInput
          placeholder={!hasLabel ? 'Phone Number' : ''}
          mask={mask}
          className='form-control'
          onChange={handleFieldChange}
          onInput={handleFieldChange}
          name='phonenumber'
          value={formData.phonenumber}
          // onChange={props.onChange}
          // onBlur={props.onBlur}
          // autoCapitalize='off'
          // autoCorrect='off'
        />
      </Form.Group>

      <Form.Group>
        <Button
          type='submit'
          color='primary'
          className='mt-3 w-100'
          disabled={!isSubmittable}
        >
          {isRegistering
            ? (
              <FontAwesomeIcon
                icon='circle-notch'
                className='me-1 fa-spinner fa-spin'
              />
              )
            : (
                ''
              )}{' '}
          Register
        </Button>
      </Form.Group>
    </Form>
  )
}

RegistrationForm.propTypes = {
  success: PropTypes.func,
  hasLabel: PropTypes.bool
}

RegistrationForm.defaultProps = {
  success: null,
  hasLabel: false
}

export default RegistrationForm
