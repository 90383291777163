/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useContext, useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Container, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import EditUser from 'modules/driver-dashboard/components/EditUser';
import EditUserPassword from 'modules/driver-dashboard/components/EditUserPassword';
import Spinner from 'react-bootstrap/Spinner';
import DriverDashboardLogo from 'modules/driver-dashboard/assets/images/dd-icon.png';
import CustomerContext from 'context/CustomerContext';
import { postDataToAPI } from 'helpers/api';

export default function DriverDashboardUserManager () {
  const [userPool, setUserPool] = useState(null);
  const [users, setUsers] = useState(null);
  const [usersTable, setUsersTable] = useState(null);
  const [oEditing, setEditing] = useState(null);
  const [aStoredData, setStoredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedCustomer, customerHasApp } = useContext(CustomerContext);

  const oPageHeader = (
    <PageHeader image={DriverDashboardLogo} title='Driver Dashboard User Manager' description='Driver Dashboard User Manager' className='mb-3' />
  );

  /**
   * On load trigger
   */
  useEffect(() => {
    if (customerHasApp('driver_dashboard')) {
      // Load the user pools
      initializeUserPoolForCustomer();
    } else {
      // redirect home.
      window.location.href = '/';
    }
    // Set default user pool
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      initializeUserPoolForCustomer();
    } else {
      setUserPool(null);
      setMessage('There is no customer selected. Please select a customer.');
    }
  }, [selectedCustomer]);

  /**
   * Whenever userPool changes.
   */
  useEffect(() => {
    loadUsers(userPool);
  }, [userPool]);

  /**
   * Whenever users changes.
   */
  useEffect(() => {
    renderUsers(users);
  }, [users]);

  /**
   * Whenever loading changes.
   */
  useEffect(() => {
    if (loading) {
      const oMarkup = (
        <Container fluid='true'>
          <Row>
            <Col>
              <Spinner animation="border" variant="info" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span className='ms-2'>Loading...</span>
            </Col>
          </Row>
        </Container>
      );
      setMessage(oMarkup);
      setLoading(false);
    }
  }, [loading]);

  function initializeUserPoolForCustomer () {
    setLoading(true);
    const sUserPool = selectedCustomer.config.apps.driver_dashboard.user_pool;
    setUserPool(sUserPool);
  }

  /**
   * Returns the actions format for the users table.
   * @param oUser
   */
  function getActionsFormat (oUser) {
    const nUserId = oUser.id;

    return (
      <div>
        <Button data-user-id={nUserId} id={'edit-' + nUserId} variant='outline-primary' size='sm' className='me-2' onClick={editUser}>
          Edit
        </Button>
        <a className='btn btn-sm btn-outline-primary' href={`user-details/${nUserId}`}>
          View Details
        </a>
        <Button data-user-id={nUserId} id={'edit-password-' + nUserId} variant='outline-danger' size='sm' className='ms-2' onClick={editUserPassword}>
          Change Password
        </Button>
      </div>
    );
  }

  /**
   * Edits a user.
   * @param oEvent
   */
  function editUser (oEvent) {
    const nUserId = oEvent.target.getAttribute('data-user-id');
    setEditing({ user: nUserId, mode: 'edit', pool: userPool });
  }

  /**
   * Edits a user's password.
   * @param oEvent
   */
  function editUserPassword (oEvent) {
    const nUserId = oEvent.target.getAttribute('data-user-id');
    setEditing({ user: nUserId, mode: 'password', pool: userPool });
  }

  /**
   * Renders the markup for the users table.
   */
  function renderUsers (aUsers) {
    let oUserTable = (
      <Container fluid='true'>
        <Row>
          <Col>
            No users found.
          </Col>
        </Row>
      </Container>
    );

    if (aUsers && Array.isArray(aUsers) && aUsers.length > 0) {
      const columns = [
        {
          accessor: 'name',
          Header: 'Name',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'email',
          Header: 'Email',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'mobile_phone',
          Header: 'Mobile Phone',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'pool_user_status.status',
          Header: 'Status',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'actions',
          Header: 'Actions',
          headerProps: { className: 'text-900' },
        }
      ];

      // Create a table with the user data.
      oUserTable = (
        <AdvanceTableWrapper
          columns={columns}
          data={aUsers}
          sortable
          pagination
          perPage={5}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={aUsers.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      );
    }

    setUsersTable(oUserTable);
  }

  /**
   * Gets demo users.
   * @returns {[]}
   */
  async function getDemoUsers () {
    return [
      {
        id: '9999-4444-3333-2222',
        name: 'John Doe',
        pool_user_status: {
          enabled: true,
          status: 'CONFIRMED'
        },
        email: 'johndoe@roadyscorp.com',
        mobile_phone: '555-555-5555'
      },
      {
        id: '8884-4436-6533-2367',
        name: 'Jane Doe',
        pool_user_status: {
          enabled: true,
          status: 'PASSWORD_RESET'
        },
        email: 'janedoe@roadyscorp.com',
        mobile_phone: '555-555-5556'
      },
      {
        id: '8474-8474-6884-2782',
        name: 'Jim Doe',
        pool_user_status: {
          enabled: true,
          status: 'CONFIRMED'
        },
        email: 'jimdoe@roadyscorp.com',
        mobile_phone: '555-555-5557'
      },
      {
        id: '4275-5209-0472-3477',
        name: 'Jerry Doe',
        pool_user_status: {
          enabled: true,
          status: 'UNCONFIRMED'
        },
        email: 'jerrydoe@roadyscorp.com',
        mobile_phone: '555-555-5558'
      }
    ];
  }

  /**
   * Fetches users from the driver dashboard user pool(s).
   * @returns {Promise<void>}
   */
  async function fetchDDUsers (pool) {
    const sUrl = '/queryAppsync';

    try {
      const response = await postDataToAPI(sUrl,
        {
          roleArn: pool,
          query: `query { 
            listUsers { 
              items { 
                id 
                user
                name 
                email 
                mobile_phone
                pool_user_status {
                  enabled
                  status
                }
              } 
            } 
          }`
        }
      );

      return await response.data; // Return the data
    } catch (error) {
      console.error('Error in fetchDDUsers:', error);
      setMessage('An error occurred while fetching users.');
      throw error; // Re-throw the error to propagate it
    }
  }

  /**
   * Loads users from a user pool.
   */
  function loadUsers (pool) {
    if (pool === 'choose' || !pool) {
      setLoading(false);
      const tempMessage = (
        <Row>
          <Col>
            <p>Please choose a user pool to load users from.</p>
          </Col>
        </Row>
      );
      setMessage(tempMessage);
    } else {
      fetchDDUsers(pool).then((response) => {
        if (response.error) {
          setMessage('An error occurred while fetching users.');
          throw new Error((response.error ?? 'unspecified error type') + ': ' + (response.message ?? ' unspecified error'));
        }
        const oData = JSON.parse(response.body);
        const oItems = oData.data.listUsers.items;
        updateUsers(oItems);
      }).catch((error) => {
        console.error('Error in loadUsers:', error);
      });
    }
  }

  /**
   * Updates the users.
   * @param aUsers
   */
  function updateUsers (aUsers) {
    if (aUsers) {
      aUsers = aUsers.map((oUser) => {
        oUser.actions = getActionsFormat(oUser);
        return oUser;
      });
    }

    // Set stored data, which is used for editing.
    const aStoredData = [];
    if (aUsers) {
      aUsers.forEach((oUser) => {
        aStoredData[oUser.id] = oUser;
      });
    }

    if (!aUsers) {
      aUsers = [];
    }

    setStoredData(aStoredData);
    // Set the users.
    setUsers(aUsers);
  }

  /**
   * Sets a message.
   * @param mMessage
   */
  function setMessage (mMessage) {
    const oMarkup = (
      <Container fluid='true'>
        <Row>
          <Col>
            {mMessage}
          </Col>
        </Row>
      </Container>
    );
    setUsersTable(oMarkup);
  }

  /**
   * Switches the user pool.
   */
  function switchUserPool () {
    const poolPicker = document.getElementById('pool-picker');
    const poolPickerValue = poolPicker.value;

    if (!poolPickerValue || poolPickerValue === 'choose') {
      setUserPool(null);
      setMessage('Please choose a user pool to load users from.');
    } else {
      setLoading(true);
      setUserPool(poolPickerValue);
    }
  }

  /**
   * Handles the save click event.
   * @param oUser
   */
  const saveClickHandler = (oUser) => {
    setLoading(true);
    loadUsers(userPool);
    setEditing(null);
  }

  /**
   * Handles the cancel click event.
   */
  const cancelClickHandler = () => {
    setEditing(null);
  }

  let oEditModal = null;
  if (oEditing !== null) {
    switch (oEditing.mode) {
      case 'edit':
        oEditModal = (
          <EditUser
            save={saveClickHandler}
            pool={oEditing.pool}
            cancel={cancelClickHandler}
            user={aStoredData[oEditing.user]}
          />
        );
        break;

      case 'password':
        oEditModal = (
          <EditUserPassword
            save={saveClickHandler}
            pool={oEditing.pool}
            cancel={cancelClickHandler}
            user={aStoredData[oEditing.user]}
          />
        );
        break;

      default:
        oEditModal = null;
        break;
    }
  }

  return (
    <>
      {oEditModal}
      {oPageHeader}
      {usersTable}
    </>
  );
}
