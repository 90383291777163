import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer
} from 'react'
import PropTypes from 'prop-types'
import { Amplify, Auth, Hub } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { initialState, AuthReducer } from 'reducers/authReducer'
import awsconfig from 'aws-exports'
import { toast } from 'react-toastify'
import { deleteDataFromAPI } from 'helpers/api'

const AccountContext = createContext()
const AuthDispatchContext = React.createContext()

export const AccountProvider = ({ children }) => {
  const [appState, dispatch] = useReducer(AuthReducer, initialState)

  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)

  useEffect(() => {
    if (user != null) {
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          user: user.username,
          attributes: JSON.stringify(user.attributes),
          authenticated: true,
          ready: true,
        }
      })

      localStorage.setItem('currentUser', user.username)
      localStorage.setItem('currentAttribs', JSON.stringify(user.attributes))
    }
  }, [user])

  useEffect(() => {
    if (user !== null && session !== null) {
      dispatch({
        type: 'REFRESH_SESSION',
        payload: {
          // user: user,
          session: session,
          authenticated: true,
          token: session.idToken.jwtToken,
          ready: true,
          groups: session.idToken.payload['cognito:groups']
        }
      })

      localStorage.setItem('currentToken', session.idToken.jwtToken)
      localStorage.setItem('currentUserGroups', session.idToken.payload['cognito:groups'])
    }
  }, [session])

  const getUser = async () => {
    return await Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(userData => {
        return userData
      })
      .catch(() => {
        logout()
      })
  }

  const getUserSession = async () => {
    return await Auth.currentSession()
      .then(sessionData => {
        return sessionData
      })
      .catch(() => {
        logout()
      })
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'demo') {
      Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          // case 'tokenRefresh':
          case 'configured':
          case 'signIn':
          case 'cognitoHostedUI':
            getUser().then(userData => setUser(userData))
            getUserSession().then(sessionData => setSession(sessionData))
            break
          case 'signOut':
          case 'oAuthSignOut':
            setUser(null)
            dispatch({ type: 'LOGOUT' })
            localStorage.clear()
            break
          case 'signIn_failure':
            break
          case 'cognitoHostedUI_failure':
            if (data.message.includes('REGISTER_FIRST')) {
              toast.error(
                'A user account could not found.  Please register yourself before attempting to logging in.',
                {
                  autoClose: 3000,
                  onClose: () => {
                    window.location.href = '/register'
                  }
                }
              )
            }
            break
          case 'parsingCallbackUrl':
            if (data.url.includes('Already+found+an+entry+for+username')) {
              authenticateFed()
            }
            break
          case 'confirmSignUp':
          case 'customOAuthState':
            // Take User Back to Login Screen
            break
        }
      })

      Amplify.configure(awsconfig)

      // getUser().then(userData => setUser(userData))
    }
  }, [])

  const logout = async () => {
    try {
      await Auth.signOut()
    } catch (error) {
      throw new Error(error)
    }
  }

  const authenticate = async (username, password) => {
    try {
      return await Auth.signIn(username, password)
        .then(user => {
          return user
        })
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const authenticateFed = async () => {
    try {
      return await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const updatePassword = async (user, password) => {
    try {
      return await Auth.completeNewPassword(user, password)
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const signup = async (username, password, attributes) => {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: attributes
      })
      return user
    } catch (error) {
      throw new Error(error)
    }
  }

  const forgot = async (username) => {
    try {
      const data = await Auth.forgotPassword(username)
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  const forgotRecovery = async (username, code, newPassword) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword)
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  const confirm = async (username, code) => {
    try {
      return await Auth.confirmSignUp(username, code)
    } catch (error) {
      throw new Error(error)
    }
  }

  const resendRegistrationCode = async (username) => {
    try {
      const data = await Auth.resendSignUp(username)
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  const changePassword = async (oldPass, newPass) => {
    try {
      return await Auth.currentAuthenticatedUser()
        .then(async user => {
          return await Auth.changePassword(user, oldPass, newPass)
        })
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const changeAttributes = async (attributes) => {
    try {
      return await Auth.currentAuthenticatedUser()
        .then(async user => {
          return await Auth.updateUserAttributes(user, attributes)
        })
    } catch (error) {
      throw new Error(error)
    }
  }

  const verifyEmail = async (code) => {
    try {
      return await Auth.verifyCurrentUserAttributeSubmit('email', code)
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteAccount = async () => {
    try {
      return await Auth.deleteUser()
    } catch (error) {
      throw new Error(error)
    }
  }

  const deleteProfileLink = async () => {
    const details = await deleteDataFromAPI('/profile/link')
    if (details !== undefined && details.status === 200) {
      getUser().then(userData => setUser(userData))
    }
  }

  const sharedState = {
    authenticate,
    authenticateFed,
    logout,
    signup,
    forgot,
    forgotRecovery,
    confirm,
    resendRegistrationCode,
    changePassword,
    changeAttributes,
    verifyEmail,
    deleteAccount,
    updatePassword,
    deleteProfileLink,
    appState
  }

  return (
    <AccountContext.Provider
      value={sharedState}
    >
      {children}
    </AccountContext.Provider>
  )
}
// export { AccountProvider, AccountContext }

AccountProvider.propTypes = {
  children: PropTypes.any
}

export function useAccountContext () {
  const context = useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccountContext must be used within a AccountProvider')
  }

  return context
}

export function useAuthDispatch () {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AccountProvider')
  }

  return context
}
