import React from 'react'
import Rewards from 'modules/rewards/pages/index'
import RewardsReports from 'modules/report-tracker/pages/rewards-reports'

const RewardsRoutes = {
  label: 'Rewards',
  id: 'rewards',
  groups: ['rewards'],
  children: [
    {
      name: 'Main',
      id: 'rewards-index',
      groups: ['rewards'],
      icon: 'house',
      active: true,
      to: '/modules/rewards/pages/index',
      component: <Rewards />
    },
    {
      name: 'Reports',
      id: 'rewards-reports',
      groups: ['rewards'],
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Transactions',
          id: 'rewards-report-transactions',
          groups: ['rewards'],
          active: true,
          to: '/modules/rewards/pages/reports/transactions',
          component: 'modules/rewards/pages/reports/transactions'
        },
        {
          name: 'Sent Reports',
          id: 'rewards-report-sent-reports',
          groups: ['rewards'],
          active: true,
          to: '/modules/report-tracker/pages/rewards-reports',
          component: <RewardsReports />
        }
      ]
    }
  ]
}

export default RewardsRoutes
