/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import gql from 'graphql-tag';

export const onCreateUserNotification = gql(`
  subscription ($user: String!) {
    onCreateNotification(user: $user) {
      id
      createdAt
      type
      id
      message
      read
      title
      updatedAt
    }
  }`
);

export const onUpdateUserNotification = gql(`
  subscription {
    onUpdateNotification {
      createdAt
      type
      id
      message
      read
      title
      updatedAt
    }
  }`
);

export const onDeleteUserNotification = gql(`
  subscription ($user: String!) {
    onDeleteNotification(user: $user) {
      id
    }
  }`
);
