import React from 'react'
import ReportTrackerMain from 'modules/report-tracker/pages/index'
import DFSReports from 'modules/report-tracker/pages/dfs-reports'
import RewardsReports from 'modules/report-tracker/pages/rewards-reports'

const DFSRoutes = {
  label: 'Report Tracker',
  id: 'report-tracker',
  groups: ['report-tracker'],
  children: [
    {
      name: 'Main',
      id: 'report-tracker-index',
      groups: ['report-tracker'],
      icon: 'chart-line',
      active: true,
      to: 'modules/report-tracker/pages/index',
      component: <ReportTrackerMain />,
      hidden: true,
      labelDisabled: true
    },
    {
      name: 'Reports',
      id: 'dfs-reports',
      groups: ['report-tracker'],
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'DFS Reports',
          id: 'report-tracker-dfs-reports',
          groups: ['report-tracker'],
          active: true,
          to: 'modules/report-tracker/pages/dfs-reports',
          component: <DFSReports />
        },
        {
          name: 'Rewards Reports',
          id: 'report-tracker-rewards-reports',
          groups: ['report-tracker'],
          active: true,
          to: 'modules/report-tracker/pages/rewards-reports',
          component: <RewardsReports />
        }
      ]
    }
  ]
}

export default DFSRoutes
