/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React from 'react';
import DriverDashboard from 'modules/driver-dashboard/pages/index';
import UserManager from 'modules/driver-dashboard/pages/user-manager';
import UserDetails from 'modules/driver-dashboard/pages/user-details';

import UserIcon from 'modules/driver-dashboard/assets/images/avatar.png';

const oDriverDashboard = (<DriverDashboard />);
const oUserManager = (<UserManager />);
const oUserDetails = (<UserDetails />);

const DriverDashboardRoutes = {
  label: 'Driver Dashboard',
  id: 'driver-dashboard',
  groups: ['driver_dashboard'],
  children: [
    {
      name: 'Main',
      description: 'Driver Dashboard Control Panel',
      id: 'driver-dashboard-index',
      groups: ['driver_dashboard'],
      icon: 'house',
      active: true,
      to: '/modules/driver-dashboard/pages/index',
      component: oDriverDashboard
    },
    {
      name: 'User Manager',
      description: 'Manage users',
      id: 'driver-dashboard-user-manager',
      groups: ['driver_dashboard'],
      icon: 'user',
      logo: UserIcon,
      active: true,
      to: '/modules/driver-dashboard/pages/user-manager',
      component: oUserManager
    },
    {
      name: 'User Details',
      description: 'User Details',
      id: 'driver-dashboard-user-details',
      groups: ['driver_dashboard'],
      icon: 'user',
      active: true,
      hidden: true,
      labelDisable: true,
      to: '/modules/driver-dashboard/pages/user-details/:userId', // Relative path
      component: oUserDetails
    }
  ],
};

export default DriverDashboardRoutes;
