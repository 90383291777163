import React, { useState } from 'react'
import PageHeader from 'components/common/PageHeader'

const Rewards = () => {
  return (
    <>
      <PageHeader
        title='Rewards'
        description="Rewards Related Content"
        className='mb-3'
      />
      <div className='row'>
        <div className='col-12'>
          <h2>Rewards</h2>
          <p>
            Rewards content goes here.
          </p>
        </div>
      </div>
    </>
  )
}

export default Rewards
