/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState, useContext } from 'react';
import { Row } from 'react-bootstrap';
import CustomerContext from 'context/CustomerContext';

/**
 * AppMenu component
 * @returns {Element}
 * @constructor
 */
const AppMenu = () => {
  const cardComponents = [];
  const { customerHasApp } = useContext(CustomerContext);

  // Keep track of imported module paths to avoid duplicates
  let aComponents = [];

  /**
   * Import all modules in the modules directory
   * @param r
   */
  function importAll (r) {
    r.keys().forEach(key => {
      const pathComponents = key.split('/');
      if (pathComponents[0] === 'modules' && pathComponents[pathComponents.length - 1] === 'Card.jsx') {
        const moduleName = pathComponents[1];
        const component = r(key).default;
        cardComponents.push({ moduleName, component });
      }
    });
  }

  importAll(require.context('modules', true, /\.jsx$/));

  // Keep track of the number of apps
  let nAppCount = 0;

  // Iterate over the card components
  aComponents = cardComponents.map((cardComponent, index) => {
    const Component = cardComponent.component;
    const aGroups = Component.getGroups ? Component.getGroups() : [];

    console.log('Checking for app in Component:', Component.id);
    if (customerHasApp(aGroups)) {
      nAppCount++;
      return (
        <Component key={index}/>
      );
    }
    return null;
  });

  // Display a message if the customer has no apps
  const oEmptyResponse = (
    <p>This customer has no apps configured.</p>
  );

  // Return the app menu
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>Your Apps</h1>
        </div>
      </div>

      <Row>
        {nAppCount === 0 ? oEmptyResponse : aComponents}
      </Row>
    </>
  );
}

// Export the AppMenu component
export default AppMenu
