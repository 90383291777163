/* jshint esversion: 6 */
/* jshint node: true */
'use strict';

import React, { useState, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import Profile from 'pages/profile';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import AppRoutes from 'routes/routes';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from 'pages/dashboard';
import Notifications from 'pages/notifications';
import AppContext from 'context/Context';
import { useAccountContext } from 'context/Account';
import UnconfiguredAppLayout from './UnconfiguredAppLayout';
import UnconfiguredPage from 'pages/unconfigured';
import Notification from 'pages/notifications/notification';
import CustomerContext from 'context/CustomerContext';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const { appState } = useAccountContext();
  const oCustomerContext = useContext(CustomerContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  const createAppRoutes = (routes) => {
    return routes.map((route) => {
      const { to, hidden, id, component, children } = route;

      if (to) {
        return <Route key={id} path={to} element={component} labelDisable={hidden || undefined} />;
      }

      if (children) {
        return createAppRoutes(children);
      }

      return null;
    });
  };

  const appRoutesArray = AppRoutes(oCustomerContext);

  const aAppRoutes = createAppRoutes(appRoutesArray);

  return (
    <>
      <Routes>
        <Route key='errorlayout' element={<ErrorLayout />}>
          <Route key='errors404' path="errors/404" element={<Error404 />} />
          <Route eky='errors500' path="errors/500" element={<Error500 />} />
        </Route>

        <Route key='mainlayout' element={<MainLayout />}>
          <Route key='dashboard' path="/" element={<Dashboard />} />
          <Route key='notifications' path="notifications" element={<Notifications />} />
          <Route key='user-profile' path="user/profile" element={<Profile />} />
          <Route key='notifications-noteid' path="notifications/:noteId" element={<Notification />} />
          {aAppRoutes}
        </Route>
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={true}
        position={toast.POSITION.TOP_LEFT}
        theme='colored'
      />
    </>
  );
};

export default Layout;
