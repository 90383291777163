/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React from 'react';
import DFSRoutes from 'modules/dfs/routes';
import RewardsRoutes from 'modules/rewards/routes';
import DriverDashboardRoutes from 'modules/driver-dashboard/routes';
import ReportTrackerRoutes from 'modules/report-tracker/routes';

/**
 * aAppsTable
 * INSTALL A NEW MODULE OR APP HERE.
 * This is where we "install" other apps into the main app.
 * If a new module is added, it must be added here.
 * @type {{dfs, "driver-dashboard", rewards}}
 */
const aAppsTable = {
  'driver-dashboard': DriverDashboardRoutes,
  dfs: DFSRoutes,
  rewards: RewardsRoutes,
};

const YourAppsRoutes = {
  label: 'Your Apps Main',
  id: 'your-apps-main',
  groups: ['your-apps', 'my-apps', 'all'],
  labelDisable: true,
  children: [
    {
      name: 'Your Apps',
      id: 'your-apps',
      groups: ['your-apps', 'my-apps', 'all'],
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
};

const HelpRoutes = {
  label: 'Help',
  id: 'help',
  groups: ['help'],
  labelDisable: true,
  children: [
    {
      name: 'Help',
      id: 'help_help',
      groups: ['help'],
      active: true,
      icon: 'question-circle',
      children: []
    }
  ]
};

/**
 * AppRoutes Create the returned routes array.
 * @param oCustomerContext
 * @returns {{children: [{name: string, icon: string, active: boolean, id: string, to: string}], labelDisable: boolean, label: string, id: string}[]}
 * @constructor
 */
const AppRoutes = (oCustomerContext) => {
  const aExportedRoutes = [YourAppsRoutes];

  Object.entries(aAppsTable).forEach(([key, value]) => {
    aExportedRoutes.push(value);
  });

  aExportedRoutes.push(ReportTrackerRoutes);

  return aExportedRoutes;
};

export default AppRoutes;
