/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { postDataToAPI } from 'helpers/api';

/**
 * Edit User component
 * @param props
 * @returns {Element}
 * @constructor
 */
const EditUserPassword = (props) => {
  const oUser = props.user;
  const sPool = props.pool;
  const cancelCallback = props.cancel;
  const saveCallback = props.save;

  const defaultUser = {
    id: oUser.id || '',
    user: oUser.user || '',
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(defaultUser);

  /**
   * On load trigger
   */
  useEffect(() => {
    setFormData({
      ...formData,
      id: oUser.id,
    });
  }, [oUser]);

  /**
   * Handles the field change
   */
  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  /**
   * Handles the cancel button click
   */
  const handleCancel = () => {
    console.log('CANCELLING');
    cancelCallback();
  };

  /**
   * Edit user request
   * @param oPayload
   * @returns {Promise<any>}
   */
  const editUserPasswordRequest = async (oPayload) => {
    const sUrl = '/queryAppsync';
    console.log('editUserPasswordRequest was hit with payload: ', oPayload);
    const sQuery = `
      mutation {
        updateUserPassword(
          input: {
            user: "` + oPayload.user + `", 
            password: "` + oPayload.password + `"
          }) {
          user
        }
      }`;

    try {
      console.log('editUserPasswordRequest was hit with query: ', sQuery);
      const response = await postDataToAPI(sUrl, {
        roleArn: sPool,
        query: sQuery
      });

      return await response.data; // Return the data
    } catch (error) {
      console.error('Error in editUserPasswordRequest:', error);
      throw error; // Re-throw the error to propagate it
    }
  };

  /**
   * Handles the form submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (formData.password !== formData.confirm_password) {
      setIsProcessing(false);
      toast.error('Passwords do not match', {
        autoClose: false
      });
      return false;
    } else {
      try {
        const oPayload = {
          ...formData,
          user: oUser.user,
          password: formData.password
        };

        console.log('Submitting form data: ', oPayload, ' for user: ', oUser.id);
        const oResponse = await editUserPasswordRequest(oPayload);
        setIsProcessing(false);

        if (oResponse.statusCode !== 200) {
          toast.error('Error: ' + oResponse.status, {
            autoClose: false
          });
          return false;
        }

        if (!oResponse || !oResponse.body) {
          toast.error('Successful but unknown response received from server', {
            autoClose: false
          });
          return false;
        }

        if (oResponse.error) {
          toast.error(oResponse.error.message, {
            autoClose: false
          });
          return false;
        }

        toast.success('Edit user password request successfully posted');
        saveCallback();
      } catch (error) {
        setIsProcessing(false);
        toast.error(
          error.message,
          {
            autoClose: false,
          }
        );
      }
    }
  };

  /**
   * Render the component
   */
  return (
    <>
      <Modal
        centered
        show={true}
        onHide={handleCancel}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} lg={12} controlId='editUserPassword'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Cognito User</Form.Label>
                    <Form.Control
                      name='user'
                      type='text'
                      value={oUser.user}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name='password'
                      type='password'
                      value={formData.password}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name='confirm_password'
                      type='password'
                      value={formData.confirm_password}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row className='mb-3 g-3'>
                <Col className='text-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg='3'>
                  <Button
                    variant='primary'
                    type='submit'
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Update Password
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

EditUserPassword.propTypes = {
  user: PropTypes.object,
  pool: PropTypes.string,
  save: PropTypes.func,
  cancel: PropTypes.func,
}

EditUserPassword.defaultProps = {
  user: {},
  pool: '',
  save: () => { return true; },
  cancel: () => { return true; }
}

export default EditUserPassword
