/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useContext } from 'react';
import CustomerContext from 'context/CustomerContext';
import { Dropdown } from 'react-bootstrap';

const CustomerSelectionDropdown = () => {
  const { selectedCustomer, handleCustomerSelection, customers } = useContext(CustomerContext);

  const handleClick = (customer) => {
    handleCustomerSelection(customer);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="falcon-default"
        title={selectedCustomer?.name || 'Select Customer'}
        id="customer-dropdown"
        align="end"
        className="me-2">
        {selectedCustomer?.name || 'Select Customer'}</Dropdown.Toggle>
      <Dropdown.Menu className='py-2'>
        <Dropdown.Item as="button" onClick={() => handleClick(null)}>
          None
        </Dropdown.Item>
        {customers.map((customer) => (
          <Dropdown.Item key={customer.id} as="button" onClick={() => handleClick(customer)}>
            {customer.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomerSelectionDropdown;
