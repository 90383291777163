/* jshint esversion: 11 */
/* jshint node: true */
'use strict';

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, InputGroup, Row, Col, Container } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { dateTimeOptions } from 'helpers/formater';
import { isIterableArray } from 'helpers/utils';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { postDataToAPI } from 'helpers/api';

const UserRewards = ({ cardNumber }) => {
  const [rewardsData, setRewardsData] = useState([]);
  const [rewardsDataTable, setRewardsDataTable] = useState(null);
  const [options, setOptions] = useState({
    cardNumber: cardNumber
  });

  /**
   * On initial page load.
   */
  useEffect(() => {
    console.log('UserRewards useEffect was hit with: ', cardNumber);
    getUserRewardsData(options).then(data => {
      console.log('User Rewards Data:', data);
      setRewardsData(data);
    }).catch(e => {
      console.error('Error fetching user rewards data:', e);
    });
  }, [options]);

  /**
   * On rewards data change.
   */
  useEffect(() => {
    renderRewardsData(rewardsData);
  }, [rewardsData]);

  /**
   * Get the user rewards data.
   * @returns {Promise<unknown>}
   */
  async function getUserRewardsData (options) {
    const { cardNumber } = options;
    let rData;

    console.log('Fetching user rewards history for card number:', cardNumber);
    const sUrl = `/getUserRewardsHistory`;
    const response = await postDataToAPI(sUrl, {
      cardNumber,
      days: 365
    });

    if (response.data) {
      rData = response.data;
    } else {
      rData = [];
    }
    return rData; // Parse the JSON response body
  }

  /**
   * Renders the rewards data.
   * @param rewardsData
   */
  function renderRewardsData (rewardsData) {
    let oRewardsDataTable;

    console.log('renderRewardsData was hit with rewardsData:', rewardsData);

    const oHistory = rewardsData.history ?? false;

    if (!oHistory || Object.keys(oHistory).length === 0) {
      oRewardsDataTable = (<Row><Col><p>No rewards data found.</p></Col></Row>);
    } else {
      const aColumns = [
        {
          accessor: 'date',
          Header: 'Date',
          HeaderProps: { className: 'text-nowrap' },
          cell: ({ row: { original } }) => {
            return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(original.date));
          }
        },
        {
          accessor: 'location_id',
          Header: 'LID',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'name',
          Header: 'Location',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'transaction_ref_num',
          Header: 'Trx Ref Num',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'terminal_device_type',
          Header: 'Device',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'gal',
          Header: 'Gal',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'shw',
          Header: 'Shw',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'usd',
          Header: 'USD',
          HeaderProps: { className: 'text-nowrap' }
        },
        {
          accessor: 'multiplier',
          Header: 'Multiplier',
          HeaderProps: { className: 'text-nowrap' }
        }
      ];

      oRewardsDataTable = (
        <AdvanceTableWrapper
          columns={aColumns}
          data={oHistory}
          sortable
          pagination
          perPage={10}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={oHistory.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      );
    }
    setRewardsDataTable(oRewardsDataTable);
  }

  /**
   * Finally, return the component.
   */
  let oReturn;
  if (!rewardsDataTable) {
    oReturn = (
      <div>
        <Skeleton count={5} />
      </div>
    );
  } else {
    oReturn = (
      <div>
        {rewardsDataTable}
      </div>
    );
  }

  return (<>{oReturn}</>);
}

/**
 * Set up the prop stuff.
 * @type {{report: Validator<NonNullable<string>>, locations: Validator<NonNullable<any[]>>}}
 */
UserRewards.propTypes = {
  cardNumber: PropTypes.string
}

export default UserRewards;
